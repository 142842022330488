import React from "react";
import { createBrowserHistory } from "history";
import './App.css';
import Layout from './components/Layout';
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter
} from "react-router-dom";
import { connect } from 'react-redux';
import CheckPage from "./components/CheckPage";
import HomePage from "./components/HomePage";
import CancelPage from "./components/CancelPage";
import SuccessPage from "./components/SuccessPage";
const browserHistory = createBrowserHistory({ basename: '' });


class App extends React.PureComponent<{}> {
  public render() {
      return (
          <Layout>
            <BrowserRouter>
              <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/check/:id" component={CheckPage} />
              <Route path="/cancel/:session_id" component={CancelPage} />
              <Route path="/success/:session_id" component={SuccessPage} />
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
            </BrowserRouter>
          </Layout>
      );
  }
}
export default connect()(App);

