import * as React from 'react';
import { connect } from 'react-redux';

const HomePage = () => (
  <div>
      OhWaiter Payment Platform
  </div>
);

export default connect()(HomePage);
