import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

const { REACT_APP_API_URL } = process.env;

export interface SessionState {
    checkIdentifier : string ,
    sessionStatus : string;
    checkStatus : string
    sessionId : string,
    loading: boolean,
}

export interface SessionView {
    checkIdentifier: string;
    stripeSessionId: string;
    sessionStatus : string;
    checkStatus : string
    sessionCreatedAt : Date;
}


interface GetSessionAction {
    type: 'GET_SESSION';
    sessionId: string;
}

interface GetSessionSuccessAction {
    type: 'GET_SESSION_SUCCESS';
    sessionView: SessionView;
}

interface GetSessionFailureAction {
    type: 'GET_SESSION_FAILURE';
}

interface CancelSessionAction {
    type: 'CANCEL_SESSION';
    sessionId: string;
}

interface CancelSessionSuccessAction {
    type: 'CANCEL_SESSION_SUCCESS';
    sessionView: SessionView;
}

interface CancelSessionFailureAction {
    type: 'CANCEL_SESSION_FAILURE';
}

interface SuccessSessionAction {
    type: 'SUCCESS_SESSION';
    sessionId: string;
}
interface SuccessSessionFailureAction {
    type: 'SUCCESS_SESSION_FAILURE';
}
interface SuccessSessionSuccessAction {
    type: 'SUCCESS_SESSION_SUCCESS';
    sessionView: SessionView;
}

type Actions =  CancelSessionAction | 
                CancelSessionSuccessAction | 
                CancelSessionFailureAction |
                SuccessSessionAction |
                SuccessSessionSuccessAction |
                SuccessSessionFailureAction |
                GetSessionAction |
                GetSessionSuccessAction |
                GetSessionFailureAction ;

export const actionCreators = {
    getSession : (sessionId : string) :  AppThunkAction<Actions> => async (dispatch) => {
        try {
            dispatch({ type: 'GET_SESSION', sessionId});
            var response = await fetch(`${REACT_APP_API_URL}/api/session/${sessionId}`);
            if (response.ok) {
                var responseJson = await response.json();
                var sessionView = responseJson as SessionView;
                dispatch({ type: 'GET_SESSION_SUCCESS', sessionView})
            } else 
            {
                dispatch({ type: 'GET_SESSION_FAILURE'})
            }
        } catch (error) {
            dispatch({ type: 'GET_SESSION_FAILURE'})
            console.log("Get Session Error: ", error);
        }
    },
    successSession: (sessionId: string): AppThunkAction<Actions> => async (dispatch) => {
        try {
            dispatch({ type: 'SUCCESS_SESSION', sessionId})
            var response = await fetch(`${REACT_APP_API_URL}/api/session/success`,
                                        {   method: 'PUT',
                                            cache: 'no-cache',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                            sessionId : sessionId
                                        })
                                    });
            if (response.ok) {
                var responseJson = await response.json();
                var sessionView = responseJson as SessionView;
                dispatch({ type: 'SUCCESS_SESSION_SUCCESS', sessionView})
            } else {
                dispatch({ type: 'SUCCESS_SESSION_FAILURE'})
            }

        } catch (error) {
            dispatch({ type: 'SUCCESS_SESSION_FAILURE'})
        }
    },

    cancelSession: (sessionId: string): AppThunkAction<Actions> => async (dispatch) => {
        try {
            dispatch({ type: 'CANCEL_SESSION', sessionId})
            var response = await fetch(`${REACT_APP_API_URL}/api/session/cancel`,
                                         { method: 'PUT',
                                         cache: 'no-cache',
                                         headers: {
                                            'Content-Type': 'application/json'
                                         },
                                         body: JSON.stringify({
                                            sessionId : sessionId
                                         })
                                        });

            console.log(response);
            if (response.ok) {
                var responseJson = await response.json();
                var sessionView = responseJson as SessionView;
                dispatch({ type: 'CANCEL_SESSION_SUCCESS', sessionView })
            } else {
                dispatch({ type: 'CANCEL_SESSION_FAILURE'})
            }
           
        } catch (error) {
            dispatch({ type: 'CANCEL_SESSION_FAILURE'})
        }
    }
};


const unloadedState: SessionState = { 
    checkIdentifier: "", 
    sessionId: "",
    sessionStatus : "",
    checkStatus : "",
    loading: false};

export const reducer: Reducer<SessionState> = (state: SessionState | undefined, incomingAction: Action): SessionState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as Actions;
    switch (action.type) {
        case 'GET_SESSION':
            return {
                ...state,
                loading : true,
                sessionId : action.sessionId
            };
        case 'GET_SESSION_SUCCESS':
            return {
                ...state,
                loading : false,
                checkIdentifier : action.sessionView.checkIdentifier,
                sessionId : action.sessionView.stripeSessionId,
                sessionStatus : action.sessionView.sessionStatus,
                checkStatus : action.sessionView.checkStatus
            };
        case 'GET_SESSION_FAILURE':
            return {
                ...state,
                loading : false
            };
            
        case 'SUCCESS_SESSION':
            return {
                ...state,
                loading : true,
                sessionId : action.sessionId
            };            
        case 'SUCCESS_SESSION_SUCCESS' :
            return {
                ...state,
                loading : false,
                checkIdentifier : action.sessionView.checkIdentifier,
                sessionId : action.sessionView.stripeSessionId,
                sessionStatus : action.sessionView.sessionStatus,
                checkStatus : action.sessionView.checkStatus
            };
        case 'SUCCESS_SESSION_FAILURE' :
            return {
                ...state,
                loading : false
            };


        case 'CANCEL_SESSION':
            return {
                ...state,
                checkIdentifier : "",
                sessionId : action.sessionId,
                loading : true
            };
        case 'CANCEL_SESSION_SUCCESS':
            return {
                ...state,
                loading : false,
                checkIdentifier : action.sessionView.checkIdentifier,
                sessionId : action.sessionView.stripeSessionId,
                sessionStatus : action.sessionView.sessionStatus,
                checkStatus : action.sessionView.checkStatus
            };
        case 'CANCEL_SESSION_FAILURE':
            return {
                ...state,
                loading : false
            };
        default: 
            return {
                ...state,
            };
    }
};
