import { Component } from "react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { ConnectedProps, connect } from "react-redux";
import { RootState } from "../store";
import * as SessionStore from '../store/Session';


class SuccessPage extends Component<PropsType, {}>  {
    
    async componentDidMount() {
        try {
            const { session_id } = this.props.match.params;
            await this.props.getSession(session_id);
            if (this.props.session.sessionStatus === "open") {
                //session is open, send success event
                await this.props.successSession(session_id);
               
            } 
            const { checkIdentifier } = this.props.session;
            console.log("check id ", checkIdentifier);
            if (checkIdentifier) {
                this.props.history.push("/check/" + checkIdentifier);
            }
        } catch (error) {
            console.log("Cancel Page Error: ", error);
        }
    }
    render() {
        return (
            <div>Payment Successful </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    session : state.session
 });
 
 const mapDispatchToProps = {
    successSession: (sessionId:string) => SessionStore.actionCreators.successSession(sessionId),
    getSession: (sessionId:string) => SessionStore.actionCreators.getSession(sessionId)
 };
 
 const connector = connect(mapStateToProps, mapDispatchToProps);
 
 type SuccessParams = { session_id: string  };
 type PropsFromRedux = ConnectedProps<typeof connector>;
 type RouteProps = RouteComponentProps<SuccessParams>;
 type PropsType = PropsFromRedux & RouteProps;
 
 export default withRouter(connector(SuccessPage));