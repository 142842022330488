import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { SessionView } from './Session';

const { REACT_APP_API_URL } = process.env;

export interface CheckState {
    checkIdentifier : string ,
    stripeAccount: string
    sessionId : string ,
    amount : number,
    adjustment : number,
    tipPercent : number,
    totalDue : number,
    status : string,
    restaurantName: string,
    tableName : string ,
    loading: boolean,
    checkFound: boolean
}

interface CheckView {
    checkIdentifier : string
    stripeAccount : string
    amount: number,
    adjustment: number;
    status : string
    tableName : string,
    restaurantName: string,
    createdAt: Date
}

interface GetCheckAction {
    type: 'GET_CHECK';
    checkIdentifier: string;
}

interface GetCheckSuccessAction {
    type: 'GET_CHECK_SUCCESS';
    checkView: CheckView;
}

interface GetCheckFailureAction {
    type: 'GET_CHECK_FAILURE';
}

interface SetCheckTipAction {
    type: 'SET_CHECK_TIP';
    adjustment : number
}

interface IncreaseTipAction {
    type: 'INCREASE_TIP_PERCENT';
}

interface DecreaseTipAction {
    type: 'DECREASE_TIP_PERCENT';
}

interface GetCheckSessionAction {
    type: 'GET_CHECK_SESSION';
}

interface GetCheckSessionSuccessAction {
    type: 'GET_CHECK_SESSION_SUCCESS';
    sessionId: string;
}

interface GetCheckSessionFailureAction {
    type: 'GET_CHECK_SESSION_FAILURE';
}

type Actions = GetCheckAction | 
               GetCheckSuccessAction | 
               GetCheckFailureAction |
               SetCheckTipAction |
               GetCheckSessionAction |
               GetCheckSessionSuccessAction |
               GetCheckSessionFailureAction |
               IncreaseTipAction |
               DecreaseTipAction ;


export const actionCreators = {
    increaseTip : () : AppThunkAction<Actions> => async (dispatch) => {
        dispatch({ type: 'INCREASE_TIP_PERCENT'})
    },
    decreaseTip : () : AppThunkAction<Actions> => async (dispatch) => {
        dispatch({ type: 'DECREASE_TIP_PERCENT'})
    },
    getCheckSession : (checkIdentifier:string,adjustment:number ) : AppThunkAction<Actions> => async (dispatch) => {
        try {
            dispatch({ type: 'GET_CHECK_SESSION'})
            let data = { checkIdentifier, adjustment }
            var response = await fetch(`${REACT_APP_API_URL}/api/check`,
                                       { method: 'PUT',
                                         cache: 'no-cache',
                                         headers: {
                                            'Content-Type': 'application/json'
                                         },
                                         body: JSON.stringify(data)
                                        });
            if (response.ok) {
                var responseJson = await response.json();
                var checkSessionView = responseJson as SessionView;
                dispatch({ type: 'GET_CHECK_SESSION_SUCCESS', sessionId : checkSessionView.stripeSessionId})
            } else {
                dispatch({ type: 'GET_CHECK_SESSION_FAILURE'})
            }

        } catch (error) {
            console.log(error);
            dispatch({ type: 'GET_CHECK_SESSION_FAILURE'})
        }
    },
    setTip: (adjustment:number) : AppThunkAction<Actions> => async (dispatch) => {
        dispatch({ type: 'SET_CHECK_TIP', adjustment })
    },
    getCheck: (checkIdentifier: string): AppThunkAction<Actions> => async (dispatch) => {
        try {
            dispatch({ type: 'GET_CHECK', checkIdentifier})
            var response = await fetch(`${REACT_APP_API_URL}/api/check?checkId=${checkIdentifier}`);
            if (response.ok) {
                var responseJson = await response.json();
                var checkView = responseJson as CheckView;
                dispatch({ type: 'GET_CHECK_SUCCESS', checkView })
            } else {
                dispatch({ type: 'GET_CHECK_FAILURE'})
            }
           
        } catch (error) {
            console.log(error);
            dispatch({ type: 'GET_CHECK_FAILURE'})
        }
    },
};

const unloadedState: CheckState = { 
    checkIdentifier : "",
    stripeAccount: "",
    sessionId : "",
    amount : 0,
    tipPercent : 20,
    totalDue : 0,
    adjustment : 0,
    tableName : "",
    status : "",
    restaurantName : "",
    loading: true,
    checkFound: false
};

function round(n:number) : number {
    return  Math.round((n + Number.EPSILON) * 100) / 100
}
export const reducer: Reducer<CheckState> = (state: CheckState | undefined, incomingAction: Action): CheckState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as Actions;
    switch (action.type) {
        case 'INCREASE_TIP_PERCENT':
            const increaseTipPercent = state.tipPercent + 1
            const increaseAdjustment = ( (state.tipPercent + 1) / 100) * state.amount
            const increaseTotalDue = state.amount + (( (state.tipPercent + 1) / 100) * state.amount)

            return {
                ...state,
                tipPercent : increaseTipPercent,
                adjustment : Math.round((increaseAdjustment + Number.EPSILON) * 100) / 100,
                totalDue :  Math.round((increaseTotalDue + Number.EPSILON) * 100) / 100,
            };
        case 'DECREASE_TIP_PERCENT':
            const decreaseTipPercent = state.tipPercent - 1
            if (decreaseTipPercent > 0) {
                const decreaseAdjustment = ( (state.tipPercent - 1) / 100) * state.amount
                const decreaseTotalDue = state.amount + (( (state.tipPercent - 1) / 100) * state.amount)
                return {
                    ...state,
                    tipPercent : decreaseTipPercent,
                    adjustment : Math.round((decreaseAdjustment + Number.EPSILON) * 100) / 100,
                    totalDue :  Math.round((decreaseTotalDue + Number.EPSILON) * 100) / 100,
                };
            } else {

                return {
                    ...state,
                    adjustment: 0,
                    totalDue: state.amount,
                    tipPercent : 0,
                };
            }
            
        case 'GET_CHECK_SESSION':
            return {
                ...state
            };
        case 'GET_CHECK_SESSION_SUCCESS':
            return {
                ...state,
                sessionId : action.sessionId
            };
        case 'GET_CHECK_SESSION_FAILURE':
            return {
                ...state,
                sessionId : ""
            };
        case 'SET_CHECK_TIP':
            let newTotalDue = Math.round((state.amount + action.adjustment + Number.EPSILON) * 100) / 100
            return {
                ...state,
                adjustment: action.adjustment,
                totalDue : newTotalDue,
            }
        case 'GET_CHECK':
            return {
                ...state,
                checkIdentifier: action.checkIdentifier,
                loading: true
            };
        case 'GET_CHECK_SUCCESS':
            const adjustment = action.checkView.adjustment;
            if (adjustment === 0) {
                //tip set
                const initTipPercent = 20
                const initAdjustment = round(( (initTipPercent ) / 100) * action.checkView.amount)
                const initTotalDue = action.checkView.amount + initAdjustment

                return {
                    ...state,
                    checkFound: true,
                    stripeAccount : action.checkView.stripeAccount,
                    loading: false,
                    amount : action.checkView.amount,
                    tipPercent : initTipPercent,
                    tableName : action.checkView.tableName,
                    restaurantName : action.checkView.restaurantName,
                    status : action.checkView.status,
                    adjustment : initAdjustment,
                    totalDue :  initTotalDue
                };
            } else {
                return {
                    ...state,
                    checkFound: true,
                    stripeAccount : action.checkView.stripeAccount,
                    loading: false,
                    amount : action.checkView.amount,
                    tableName : action.checkView.tableName,
                    restaurantName : action.checkView.restaurantName,
                    status : action.checkView.status,
                    adjustment : adjustment,
                    totalDue :  adjustment + action.checkView.amount
                };
            }
           
        case 'GET_CHECK_FAILURE':
            return {
                ...state,
                checkIdentifier : "",
                checkFound : false,
                loading : false,
                amount : 0,
                totalDue : 0,
                adjustment : 0,
                tableName : ""
            };
        default: {
            return {
                ...state
            }
        }
    }
};
