import * as Session from './Session';
import * as Check from './Check';
import { combineReducers } from 'redux';

// The top-level state object
export interface ApplicationState {
    session: Session.SessionState | undefined
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const rootReducer = combineReducers({
    session: Session.reducer,
    check: Check.reducer
});

export type RootState = ReturnType<typeof rootReducer>

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
