import { Component } from "react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { ConnectedProps, connect } from "react-redux";
import { RootState } from "../store";
import { Grid, Box, Typography, Link, makeStyles,  Container, CssBaseline, IconButton } from '@material-ui/core';
import * as CheckStore from '../store/Check';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { loadStripe } from '@stripe/stripe-js';
import { CheckState } from "../store/Check";
import ExposurePlus1 from '@material-ui/icons/ExposurePlus1';
import ExposureNeg1 from '@material-ui/icons/ExposureNeg1';

const { REACT_APP_STRIPE_KEY } = process.env;



const Copyright: React.FunctionComponent = (props) => (
  <Typography variant="body2" color="textSecondary" align="center">{'Powered by '}
    <Link color="inherit" href="https://www.ohwaiter.com/">OhWaiter</Link>{'.'}
  </Typography>
);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function formatMoney( amount : string , decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = Number(amount) < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(Number(amount) - Number(i)).toFixed(decimalCount).slice(2) : "");
    
  } catch (e) {
    console.log(e)
  }
};

const PayView: React.FunctionComponent<{state:CheckState, 
                                        increaseTip: () => void  , 
                                        decreaseTip: () => void ,
                                        handelPay: () => void }> = (props ) => {
  const classes = useStyles();
  const payText = "Pay $" + formatMoney(props.state.totalDue.toString())
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">Pay My Bill</Typography>
        <div className={classes.form} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>{props.state.restaurantName}</Grid>
            <Grid item xs={12} sm={12}>Table: {props.state.tableName}</Grid>
            <Grid item xs={12}>Check Amount: <NumberFormat value={props.state.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Grid>
            <Grid item xs={12} sm={12}>
              <Box display="flex" >
                <Box p={1} bgcolor="grey.300" alignSelf="flex-start" ><IconButton onClick={props.decreaseTip}><ExposureNeg1/></IconButton></Box>
                <Box p={1} bgcolor="grey.300" flexGrow={1} textAlign="center">
                  <Grid container spacing={1} >
                    <Grid item xs={12} sm={12}>Tip {props.state.tipPercent}%</Grid>
                    <Grid item xs={12} sm={12}><NumberFormat value={props.state.adjustment} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Grid>
                  </Grid>
                </Box>
                <Box p={1} bgcolor="grey.300"  alignSelf="flex-end"><IconButton onClick={props.increaseTip}><ExposurePlus1/></IconButton></Box>
              </Box>
            </Grid>
          </Grid>
          <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={props.handelPay}>{payText}</Button>
        </div>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
  </Container>
)};

const PaidView: React.FunctionComponent<{state:CheckState}> = (props ) => {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs" >
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">Payment Successful</Typography>
        <div className={classes.form} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>{props.state.restaurantName}</Grid>
            <Grid item xs={12} sm={12}>Table: {props.state.tableName}</Grid>
            <Grid item xs={12} sm={12}>Check Amount: <NumberFormat value={props.state.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Grid>
            <Grid item xs={12} sm={12}>Tip: <NumberFormat value={props.state.adjustment} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Grid>
            <Grid item xs={12} sm={12}>Total Paid: <NumberFormat value={props.state.totalDue} displayType={'text'} thousandSeparator={true} prefix={'$'} /></Grid>
            <Grid item xs={12} sm={12}>Thank you! Please come again!</Grid>
            <Grid item xs={12} sm={12}> Statement will show charge from OhWaiter. Please take a picture for your records and mark restaurant name if needed.</Grid>
          </Grid>
        </div>
      </div>
      <Box mt={5}><Copyright /></Box>
    </Container>
)};

const CancelView: React.FunctionComponent<{state:CheckState}> = (props) => {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <Typography component="h1" variant="h5">Payment Cancelled</Typography>
      <div className={classes.form} >
        Ask Server To Send Another Check.
      </div>
      </div>
    </Container>
  );
}

const DefaultView: React.FunctionComponent<{}> = (_) => {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <Typography component="h1" variant="h5">OhWaiter Payment</Typography>
      <div className={classes.form} >
        Loading..
      </div>
      </div>
    </Container>
  );
}

class CheckPage extends Component<PropsType, {}>  {
    async componentDidMount() {
      const checkId = this.props.match.params.id;
      await this.props.getCheck(checkId);
    }

    handelPay = async () => {
      try {
        const { adjustment,checkIdentifier, stripeAccount } = this.props.check;
        await this.props.getCheckSession(checkIdentifier,adjustment)
        var stripePromise = loadStripe(`${REACT_APP_STRIPE_KEY}`, {
          stripeAccount: stripeAccount
        });
        
        let stripe = await stripePromise
        let sessionId = this.props.check.sessionId
        if (sessionId && stripe) {       
          await stripe.redirectToCheckout({ sessionId });
        }
      } catch (error) {
        console.log(error);
      }
    }
    
    render() {
      const { status } = this.props.check;
      const isPaid = status === "paid";
      const isOpen = status === "open";
      const isClose = status === "close";
      const isCancelled = status === "cancel";
      if (isPaid || isClose) {
        return (
          <PaidView state={this.props.check} />
        );
      } else if (isCancelled){
        return (
          <CancelView state={this.props.check} />
        );
      } else if (isOpen){
        return (
          <PayView state={this.props.check} 
                  increaseTip={ () => this.props.increaseTip()} 
                  decreaseTip={ () => this.props.decreaseTip()} 
                  handelPay={() => this.handelPay() }/>
        );
      } else {
        return (
          <DefaultView  />
        );
      }
    }
  }

const mapStateToProps = (state: RootState) => ({
   check : state.check
});

const mapDispatchToProps = {
  increaseTip: () => CheckStore.actionCreators.increaseTip(),
  decreaseTip: () => CheckStore.actionCreators.decreaseTip(),
  getCheck: (checkId:string) => CheckStore.actionCreators.getCheck(checkId),
  setTip: (tipAmmount:number) => CheckStore.actionCreators.setTip(tipAmmount),
  getCheckSession : (checkId:string,adjustment:number ) => CheckStore.actionCreators.getCheckSession(checkId,adjustment)
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type CheckParams = { id: string  };
type PropsFromRedux = ConnectedProps<typeof connector>;
type RouteProps = RouteComponentProps<CheckParams>;
type PropsType = PropsFromRedux & RouteProps;

export default withRouter(connector(CheckPage));